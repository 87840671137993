import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Referrals from '../Social/Referrals';

const Tokens = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle='Tokens' parent='Dashboard' title='Tokens' />
            <Container fluid={true}>
                <Row>
                    <Col xl='12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>PV</th>
                                    <th>RV</th>
                                    <th>JVK</th>
                                    <th>GFT</th>
                                    <th>PWR</th>
                                    <th>GOV</th>
                                    <th>SKU12</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col xl='12'><Referrals /></Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Tokens;