import React, { useEffect } from 'react';
import { ChevronsUp } from 'react-feather';
import { useState } from 'react';
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../../constants/config";

const Taptop = (props) => {
/*
    const user = useSelector(
        (state) => state.userSettings.email
    );

    const [sponsor, setSponsor] = useState("");
    const [tmpLink, setTmpLink] = useState("");

    useEffect(() => {
        getUser();
    }, [user])

    async function getUser() {
        try {
            if (user != '') {
                const res = await axios.get(apiUrl + "userinfo/" + btoa(user));
                if (res.data.data) {
                    if (res.data.data.sponsor_address == '') {
                        setTmpLink("aW5mb0BqaXZha2FnbG9iYWwuY29t");
                    } else {
                        setSponsor(res.data.data.sponsor_address);
                    }
                }
            }
        } catch (e) {

        }
    } 
    */

    return (
        <div className="tap-top" style={{ display: 'block' }}></div>
    );
};

export default Taptop;