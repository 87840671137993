import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Btn, H5, Image } from '../../../AbstractElements';
import { HaveYouTriedOur, MobileApplication, TryNow } from '../../../Constant';

import wave from '../../../assets/images/dashboard-5/wave.png';
import mobileImage from '../../../assets/images/dashboard-5/mobile-img.png';

import MyAlgoConnect from "@randlabs/myalgo-connect";

const MobileAppCard = () => {
  const [address, setAddress] = useState("");

  const connectWallet = async () => {
    new MyAlgoConnect().connect()
        .then(accounts => {
            const _account = accounts[0];
            setAddress(_account.address);
        }).catch(error => {
        console.log('Could not connect to MyAlgo wallet');
        console.error(error);
    })
  };

  const disconnect = () => {
      setAddress(null);
  };
  return (
    <Card className='mobile-app-card'>
      <CardHeader className='card-no-border pb-0'>
        <H5 attrH5={{ className: 'mb-3' }}>
          <span className='f-16 f-light'>Connect your </span><br />
          Algorand Wallet:
        </H5>
      </CardHeader>
      <CardBody className='p-3 text-end'>
          {address ?
          <Btn attrBtn={{ color: 'primary', className: 'purchase-btn btn-hover-effect f-w-500', type: 'button', onClick: disconnect }}>{address}</Btn>
          :
          <Btn attrBtn={{ color: 'primary', className: 'purchase-btn btn-hover-effect f-w-500', type: 'button', onClick: connectWallet }}>Connect</Btn>
          }
      </CardBody>
    </Card>
  );
};

export default MobileAppCard;
