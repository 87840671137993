import React, { useState } from "react";
import ModalLogin from "../ModalLogin/ModalLogin";
import ModalPages from "../ModalPages/ModalPages";
import Modal from "../Modal/Modal";

import { Link } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import parse_html from "html-react-parser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../Styles/Style.css";

/*import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode, Keyboard, Mousewheel } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";*/

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem, Divider } from "rc-menu";
import "rc-dropdown/assets/index.css";

export default function Landing(props) {
    const [isOpen, setIsOpen] = useState(false);

    const { height, width } = useWindowDimensions();

    const [showMenu, setShowMenu] = useState(false);
    const [burgerMenu, setBurgerMenu] = useState(false);

    const headerActive = showMenu ? "active" : "";
    const headerClasses = ["header", headerActive];

    const burgerActive = showMenu ? "active" : "";
    const burgerClasses = ["burgerMenu", burgerActive];

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    const [contLocale, setContLocale] = useState("");

    const user = useSelector(
        (state) => state.userSettings.email
    );

    function onSelect({ key }) {
        i18n.changeLanguage(key);
        setContLocale(key);
    }

    function onVisibleChange(visible) {
        // console.log(visible);
    }

    function toDashboard() {
        navigate("/dashboard");
    }

    const menu = (
        <Menu onSelect={onSelect}>
            <MenuItem key="en">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/en.svg" alt="" />
                    <div className="lang">En</div>
                </a>
            </MenuItem>
            <MenuItem key="uk">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/uk.svg" alt="" />
                    <div className="lang">Uk</div>
                </a>
            </MenuItem>
            <MenuItem key="es">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/es.svg" alt="" />
                    <div className="lang">Es</div>
                </a>
            </MenuItem>
            <MenuItem key="tr">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/tr.svg" alt="" />
                    <div className="lang">Tr</div>
                </a>
            </MenuItem>
            <MenuItem key="id">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/id.svg" alt="" />
                    <div className="lang">Id</div>
                </a>
            </MenuItem>
            <MenuItem key="zh">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/zh.svg" alt="" />
                    <div className="lang">Zh</div>
                </a>
            </MenuItem>
            <MenuItem key="pl">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/pl.jpg" alt="" />
                    <div className="lang">Pl</div>
                </a>
            </MenuItem>
            <MenuItem key="ru">
                <a className="opHover" href="#">
                    <img src="/skins/jivakag/img/flags/ru.svg" alt="" />
                    <div className="lang">Ru</div>
                </a>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <div className="fixed-header">
		<div className="fixed-header__inner container-fluid">
			<div className="logo">
				<a href="#">
					<img src="/skins/jivakag/logo.png" />
				</a>
			</div>
			<div className="fixed-header__button-holder">
            {user == '' ?
				<a href="#" className="fixed-header__button" onClick={() => {
                                                                setIsOpen(
                                                                    true
                                                                );
                                                            }}>Login</a>
            :
            <a href="#" className="fixed-header__button" onClick={() => toDashboard()}>Profile</a>
            }
			</div>
		</div>
	</div>
		<div className="firstBlock">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="firstBlockInner">
							<p>Balanced Soil / Balanced Body / Balanced Economy</p>
							<h1>Health & Wealth<br/>Through Balance</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="secondBlock">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="secondBlockInner">
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="secondBlock__info">
										<h2><img src="/skins/jivakag/jivakaHead.svg" /></h2>
										<h3>The Miracle of Herbs</h3>
										<p>
											Jivaka is a unique combination of herbs, spices and fruits formulated to balance your body and optimize how it functions. </p>
										<p>
											The company has been GHPs / HACCP certified by QRO Certification (Thailand) for production of dietary supplement product.
										</p>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="secondBlockImgs">
										<img src="/skins/jivakag/secondBlockImg.jpg" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="full-green-block full-green-block__second">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						
						<div className="miniBlock miniBlockOne">
							<div className="row">
								<div className="col-12 col-md-6">
									<h2>Unique Blend of Quality Ingredients</h2>
									<p>Jivaka’s synergistic formulation is a proprietary blend of herb and fruit extracts</p>
								</div>
								<div className="col-12 col-md-6"><img src="/skins/jivakag/pharma.png" className="w-100" /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="thirdBlock">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-md-6">
						<h2>The Benefits of Balance</h2>
						<p className="blackP mb-5">When the four elements of the body are in balance, the whole system functions better:</p>
						<p className="greenP text-uppercase">BLOOD CIRCULATION</p>
						<p className="blackP mb-4">Increased oxygen flow throughout the circulatory system leading to cleaner blood, better breathing and more energy</p>
						<p className="greenP text-uppercase">BLOOD STRENGTH</p>
						<p className="blackP mb-4">Strengthened blood plasma leading to enhanced suppression of malicious pathogens (viruses, bacteria) and improved homeostasis</p>
						<p className="greenP text-uppercase">BLOOD FUNCTIONS</p>
						<p className="blackP mb-4">Improved blood functions leading to enhanced distribution of cellular nutrients and increased removal of fat, carbon dioxide, and toxins</p>
						<p className="greenP text-uppercase">BONES AND JOINTS</p>
						<p className="blackP mb-4">Increased elasticity in tendons and ligaments leading to reduced friction between bones and decreased joint inflammation</p>
						<p className="greenP text-uppercase">VITAL ORGANS</p>
						<p className="blackP mb-4">Improved nourishment of the brain and other vital organs leading to better organ function and prevention of high blood pressure and stroke</p>
					</div>					
					<div className="col-12 col-md-6">
						<img src="/skins/jivakag/bgSkeleton.png" className="w-100" />
					</div>
				</div>
			</div>
		</div>		
		
		<div className="full-green-block full-green-block__fourth">
			<div className="fourthBlock">
				<div className="container-fluid">
					<div className="miniBlock miniBlockTwo">
						<div className="row">
							<div className="col-12 col-md-6">
								<h2>Four Elements</h2>
								<p>According to certain Asian traditions, the human body is composed of four fundamental elements: Earth, Water, Air and Fire. Substances in the body that are solid can be said to have the qualities of the Earth element. Substances that are liquid are of the Water element. Movement is the quality of the Air element, and Heat is the quality of the Fire element. Maintaining the balance of these fundamental elements within the body has been seen for centuries to be the key to maintaining optimal health.</p>
							</div>					
							<div className="col-12 col-md-6">
								<img src="/skins/jivakag/miniBlockTwo.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="full-green-block full-green-block__fifth">
			<div className="fifthBlock">
				<div className="container-fluid">
					<div className="fifthBlockInner">
						<div className="row justify-content-end">
							<div className="col-12 col-md-6 align-self-start">
								<h2>The Legend of Jivaka</h2>
								<p>Jivaka was the name of the personal physician of the Buddha. He lived in northeastern India in the 5th century BC. Known as the “Medicine King”, Jivaka was a patriarch of traditional healing and is featured prominently in legendary accounts in Asia as a model healer. Jivaka was renowned for his knowledge of diseases and their cures. He believed that plant-based formulations played a vital role in restoring and maintaining human health.</p>
							</div>
							<div className="col-12 col-md-6">
								<img className="fifthBlock__img" src="/skins/jivakag/legenfImg.jpg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="sixthBlock">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h2 className="mb-5">Jivaka Global</h2>
						<p className="blackP">There is a global marketing and distribution system called Jivaka Global which offers members significant discounts on wholesale purchases (starting at 10 boxes) as well as a range of referral incentives. Jivaka Global is a highly unique program built on a decentralized system of marketing and distribution. Substantial rewards are available to those who build large organizations of wholesale Jivaka purchasers.</p>
						<p className="blackP">If you are interested in becoming a wholesale member of Jivaka Global, please get in contact with an existing member or contact us at: info@jivaka.info.</p>
					</div>
				</div>
			</div>
		</div>
		<div className="seventhBlock">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h2 className="mb-5">Analysis Report</h2>
						<p className="blackP">From Asia Medical and Agricultureal Laboratory and Research Center</p>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12">
						<p className="greenP">1. Nutritional report from Jivaka capsule and Jivaka beverage.</p>
					</div>
				</div>				
				<div className="row">
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc1.jpg" />
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc2.jpg" />
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc3.jpg" />
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc4.jpg" />
					</div>
				</div>				
				<div className="row mt-4">
					<div className="col-12">
						<p className="greenP">2. Contamination analysis report. This clearly shown that both of our products are well above limits, and safe for daily consumption.</p>
					</div>
				</div>				
				<div className="row">
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc5.jpg" />
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc6.jpg" />
					</div>
					<div className="col-12 col-md-6 col-lg-3">
						<img src="/skins/jivakag/doc7.jpg" />	
					</div>
					</div>
				</div>
		</div>
		<div className="footer_cont">
			<img src="/skins/jivakag/logo-footer.svg" />
			<p>All rights reserved (с) 2023</p>
		</div>
            {isOpen && (
                <ModalLogin
                    visible={isOpen}
                    setVisible={setIsOpen} 
                    setIsAuth={props.setIsAuth}
                ></ModalLogin>
            )}
        </>
    );
}
