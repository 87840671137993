import React, {useState} from 'react';
import { TrendingDown, TrendingUp } from 'react-feather';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { AllCampaignsTitle, DailyDropdown } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';

const Referrals = () => {
  const [totalReferrals, setTotalReferrals] = useState(0);

  const AllCampaignsTable = {
    header: ['Referral Ring', 'Jivaka ID', 'Flag', 'PV', 'RV', 'Date'],
    body: [
      {
        ADPlatform: "facebook",
        ring: 'R1',
        id: 'Jivaka ID',
        flag: 123,
        pv: 200,
        rv: 100,
        date: "21.01.2023"
      },
      {
        ADPlatform: "instagram",
        ring: 'R2',
        id: 'Jivaka ID',
        flag: 123,
        pv: 300,
        rv: 100,
        date: "21.01.2023"
      }
    ],
  };
  return (
    <Card>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 className='m-0'>Referrals</H5>
        </div>
      </CardHeader>
      <CardBody className='pt-0 campaign-table'>
        <div className='recent-table table-responsive currency-table'>
          <Table>
            <thead>
              <tr>
                {AllCampaignsTable.header.map((item, i) => (
                  <th key={i} className='f-light'>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {AllCampaignsTable.body.map((item, i) => (
                <tr key={i}>
                  <td className={`border-icon ${item.ADPlatform}`}>
                    <div>
                      <div className='social-circle'>
                      {item.ring}
                      </div>
                    </div>
                  </td>
                  <td>{item.id}</td>
                  <td>{item.flag}</td>
                  <td>
                    <div className='change-currency'>
                      <TrendingUp className='font-success me-1' />
                      {item.pv}
                    </div>
                  </td>
                  <td>
                    <div className='change-currency'>
                      <TrendingUp className='font-success me-1' />
                      {item.rv}
                    </div>
                  </td>
                  <td>
                    {item.date}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr><td>Total Team Referrals: {totalReferrals}</td></tr>
            </tfoot>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default Referrals;
