import React, {useState} from "react";
import s from "./ModalLogin.module.css";
import { Magic } from 'magic-sdk';
import { AuthExtension } from '@magic-ext/auth';
import { useNavigate } from "react-router-dom";
import {setUserEmail} from "../../state/userSettings/reducer";
import {useDispatch} from "react-redux";
import { FallingLines } from  'react-loader-spinner'

import { MagicLogin } from '../../utils/magiclogin';

export default function ModalLogin({visible, setVisible, setIsAuth}, props) {
    const dispatch = useDispatch();
    const rootClasses = [s.Modal];

    let navigate = useNavigate();

    if (visible) {
        rootClasses.push(s.active);
    }

    const closeModal = (event) => {
        event.preventDefault();
        setVisible(false);
    };

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        setIsLoading(true);
        try {
        const magic = new Magic(process.env.REACT_APP_PUBLIC_MAGICLINK_KEY, {
            extensions: [new AuthExtension()]
          });

        const didToken = await magic.auth.loginWithEmailOTP({ email: email });
        
        if (didToken) {
            localStorage.setItem('didToken', didToken);
            dispatch(
                setUserEmail({
                    email: email
                })
            );
            setIsAuth(true);
            navigate("/dashboard");
        }
        } catch (error) {
            alert(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }

        /*let is_login = await MagicLogin(didToken);
        if (is_login) {
            navigate("/dashboard");
        } else {

        }*/
      };

    return (
        <div className={rootClasses.join(" ")} onClick={closeModal}>
            <div
                className={s.ModalWrapper}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.ModalСontent}>
                    <div className={s.modalHeader}>
                        <h5 className={s.modalTitle} id="makeLoginLabel">Login</h5>
                        <img className={s.modalTitleImg} src="/skins/jivakag/donationIcon.svg" />
                    </div>
                    <div className={s.ModalBody}>
                        {isLoading ?
                        <FallingLines
                            color="#4fa94d"
                            width="100"
                            visible={true}
                            ariaLabel='falling-lines-loading'
                        />
                        :
                        <>
                        <p className={s.jivakaid}>Please, enter your credentials</p>
                        <input type="text" className={s.brandInput} placeholder="@username" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <a href="#" onClick={() => onSubmit()} className={s.brandButton}>Login</a>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
