import React, { useState } from 'react';
import s from './ModalPages.module.css';


function Modal({children, visible, setVisible}, props) {
	const rootClasses = [s.Modal]

	if(visible){
		rootClasses.push(s.active);
	}

	const closeModal = (event) => {
		event.preventDefault();
		setVisible(false);
	}

	return ( 
                <div className="modalPage">
					<div className="modalPageInner">
						<div className="topLine"><div className={s.close} onClick={closeModal}></div></div>
						<div id="modalContent">
                            {children}
						</div>
					</div>
				</div>
	);
}

export default Modal;