import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Tabs from './Tabs';

const LeaderBoard = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle='Leader board' parent='Dashboard' title='Leader board' />
            <Container fluid={true}>
                <Row>
                    <Col xl={12}>
                        <Tabs />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default LeaderBoard;