import React, { Fragment, useState } from 'react';
import { Card, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { P } from '../../../AbstractElements';
import { Febric, Video, Details, Brand } from '../../../Constant';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  return (
    <Fragment>
      <Card>
        <div className='product-page-main'>
          <Row className='m-0'>
            <Col sm='12'>
              <Nav tabs className='border-tab nav-primary mb-0 '>
                <NavItem id='myTab' role='tablist'>
                  <NavLink href='#' className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                    Top 10
                  </NavLink>
                  <div className='material-border'></div>
                </NavItem>
                <NavItem id='myTab' role='tablist'>
                  <NavLink href='#' className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                    Referral Volume
                  </NavLink>
                  <div className='material-border'></div>
                </NavItem>
                <NavItem id='myTab' role='tablist'>
                  <NavLink href='#' className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                    Personal Volume
                  </NavLink>
                  <div className='material-border'></div>
                </NavItem>
                <NavItem id='myTab' role='tablist'>
                  <NavLink href='#' className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                    Leadership
                  </NavLink>
                  <div className='material-border'></div>
                </NavItem>
                <NavItem id='myTab' role='tablist'>
                  <NavLink href='#' className={activeTab === '5' ? 'active' : ''} onClick={() => setActiveTab('5')}>
                    Gifting
                  </NavLink>
                  <div className='material-border'></div>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                    <table className='table mb-0 m-t-20'>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Username</th>
                                <th>Flag</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>@Username</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tabId='2'>
                    <table className='table mb-0 m-t-20'>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Username</th>
                                <th>Flag</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>@Username</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tabId='3'>
                    <table className='table mb-0 m-t-20'>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Username</th>
                                <th>Flag</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>@Username</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tabId='4'>
                    <table className='table mb-0 m-t-20'>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Username</th>
                                <th>Flag</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>@Username</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tabId='5'>
                    <table className='table mb-0 m-t-20'>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Username</th>
                                <th>Flag</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>@Username</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Card>
    </Fragment>
  );
};

export default Tabs;
