import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        address: '',
        nativeBalance: 0,
        tokenBalance: 0,
    },
    reducers: {
        setUserInfo(state, {payload: { address, nativeBalance }}) {
            state.address = address;
            state.nativeBalance = nativeBalance;
        },
        setUserTokenBalance(state, {payload: {tokenBalance}}) {
            state.tokenBalance = tokenBalance;
        }
    }
});

export const {setUserInfo,setUserTokenBalance} = userSlice.actions;
export default userSlice.reducer;