import { createSlice } from "@reduxjs/toolkit";

const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState: {
        customer_login: "",
        first_name: "",
        email: ""
    },
    reducers: {
        setUserSettings(state, {payload: 
            { customer_login,
                first_name
            }}) {
            state.customer_login = customer_login;
            state.first_name = first_name;
        },
        setUserCountry(state, {payload: {country}}) {
            state.country = country;
        },
        setUserEmail(state, {payload: {email}}) {
            state.email = email;
        }
    }
});

export const {setUserSettings, setUserCountry, setUserEmail} = userSettingsSlice.actions;
export default userSettingsSlice.reducer;