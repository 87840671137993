export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards",
    Items: [
      { path: '/dashboard', icon: "home", title: "Dashboard", type: "link" },
    ],
  },

  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      { path: '/dashboard/leaderboard', icon: "social", title: "Leader Board", type: "link" },
      { path: '/dashboard/tokens', icon: "charts", title: "Tokens", type: "link" },
    ],
  },
];
