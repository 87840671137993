import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Image, H5, LI, UL } from '../../../AbstractElements';

import profileImage from '../../../assets/images/dashboard-5/profile.png';
import SvgIcon from '../../Common/Component/SvgIcon';
import {apiUrl} from "../../../constants/config";
import axios from "axios";

const SocialProfileCard = () => {
  const user = useSelector(
    (state) => state.userSettings.email
  );

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [tmpLink, setTmpLink] = useState("");

  useEffect(() => {
    getUser();
  }, [user])

  async function getUser() {
    try {
        if (user != '') {
            const res = await axios.get(apiUrl + "userinfo/" + btoa(user));
            if (res.data.data) {
                setEmail(res.data.data.email);
                setUserName(res.data.data.customer_login);
                //setCountry(res.data.country);
                if (res.data.data.sponsor_address == '') {
                    setTmpLink("aW5mb0BqaXZha2FnbG9iYWwuY29t");
                } else {
                    setSponsor(res.data.data.sponsor_address);
                }
            }
        }
    } catch (e) {

    }
  }   

  const SocialProfileStatus = [
    {
      title: 'PWR Tokens',
      active: 1000,
    },
    {
      title: 'GOV Tokens',
      active: 200,
    },
    {
      title: 'Rank',
      active: 1,
    },
  ];

  return (
    <Card className='social-profile'>
      <CardBody>
        <div className='social-img-wrap'>
          <div className='social-img'>
            <Image attrImage={{ src: profileImage, alt: 'profile' }} />
          </div>
          <div className='edit-icon'>
            <SvgIcon iconId='profile-check' />
          </div>
        </div>
        <div className='social-details'>
          <H5 attrH5={{ className: 'mb-1' }}>
            <Link to={`${process.env.PUBLIC_URL}/app/social-app`}>@{userName}</Link>
          </H5>
          <span className='f-light'>{email}</span>
          <UL attrUL={{ className: 'social-follow' }}>
            {SocialProfileStatus.map((item, i) => (
              <LI key={i}>
                <H5 attrH5={{ className: 'mb-0' }}>{item.active}</H5>
                <span className='f-light'>{item.title}</span>
              </LI>
            ))}
          </UL>
        </div>
      </CardBody>
    </Card>
  );
};

export default SocialProfileCard;
