import React, { Fragment, useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import "../index.scss";

import Taptop from './TapTop';
import Header from './Header';
import Sidebar from './Sidebar';
import ThemeCustomize from '../Layout/ThemeCustomizer';
import Footer from './Footer';
import CustomizerContext from '../_helper/Customizer';
import AnimationThemeContext from '../_helper/AnimationTheme';
import ConfigDB from '../Config/ThemeConfig';
import Loader from './Loader';
import Social from "../Components/Dashboard/Social";
import LeaderBoard from "../Components/Dashboard/LeaderBoard";
import Tokens from "../Components/Dashboard/Tokens";

import {jivakaWebSite} from "../constants/config";
import { useNavigate } from "react-router-dom";
import {apiUrl} from "../constants/config";
import { Magic } from 'magic-sdk';
import { AuthExtension } from '@magic-ext/auth';
import {useDispatch} from "react-redux";
import {setUserEmail} from "../state/userSettings/reducer";
import axios from "axios";
import Select from "react-select";
import "../Components/Styles/Dashboard_custom.css";

const AppLayout = (props, { children, classNames, ...rest }) => {
  const { layout } = useContext(CustomizerContext);
  const { sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme = localStorage.getItem('animation') || animation || ConfigDB.data.router_animation;

  const dispatch = useDispatch();

  const [tmpLink, setTmpLink] = useState("");
  const [countryStep, setCountryStep] = useState(false);
  const [emailStep, setEmailStep] = useState(false);
  const [loginStep, setLoginStep] = useState(false);
  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [address, setAddress] = useState("");

/*<ThemeCustomize />*/
  const user = useSelector(
    (state) => state.userSettings.email
  );

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("referralLink")) {
        checkLink();
    } else {
        if (!props.isAuth) {
            navigate("/");
        }
    }
  }, []);

  useEffect(() => {
    getUser();
  }, [user])

  const checkLink = async () => {
    if (localStorage.getItem("referralLink")) {
        let tmplink = localStorage.getItem("referralLink");
        tmplink.replace("https://jivakaglobal.com/link/", "").replace("/", "");

        setTmpLink(tmplink);
        setCountryStep(true);
        getCountriesList();
    }
  }

  async function getCountriesList() {
    try {
        const res = await axios.get(apiUrl + "countries");
        if (res.data) {
            setCountries(res.data);
        }
    } catch (e) {

    }
  }

  async function getUser() {
    try {
        if (user != '') {
            const res = await axios.get(apiUrl + "userinfo/" + btoa(user));
            if (res.data.data) {
                setEmail(res.data.data.email);
                setUserName(res.data.data.customer_login);
                //setCountry(res.data.country);
                if (res.data.data.sponsor_address == '') {
                    setTmpLink("aW5mb0BqaXZha2FnbG9iYWwuY29t");
                    setCountryStep(true);
                    getCountriesList();
                } else {
                    setSponsor(res.data.data.sponsor_address);
                }
            }
        }
    } catch (e) {

    }
  } 

  const saveCountry = () => {
    setCountryStep(false);
    setEmailStep(true);
  }

  const saveEmail = async () => {
    if (email != '') {
        setEmailStep(false);

        const magic = new Magic(process.env.REACT_APP_PUBLIC_MAGICLINK_KEY, {
            extensions: [new AuthExtension()]
          });

        const didToken = await magic.auth.loginWithEmailOTP({ email: email });

        if (didToken) {
            localStorage.setItem('didToken', didToken);
            dispatch(
                setUserEmail({
                    email: email
                })
            );
            props.setIsAuth(true)
            setLoginStep(true);               
        }      
    } else {
        alert('Enter Email');
    }
  }
  const saveUserName = async () => {
    if (userName != '') {
        setLoginStep(false);  
        setTmpLink("");

        let headers = {
            'Content-Type': 'multipart/form-data'
        }
        
        const res = await axios.post(apiUrl + "setUserData", {
        //const res = await axios.post(apiUrl + "setUserData", {
            country: country,
            email: email,
            sponsor: localStorage.getItem("referralLink"),
            wallet: btoa(email),
            login: userName
        }, {
            headers: headers
        });
        if (res.data.status == 'success') {
            localStorage.removeItem("referralLink");
        } else {
            console.log(res);
        }
    }  
  }

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${layout1}`} sidebar-layout={sideBarIcon} id='pageWrapper'>
        <Header />
        <div className='page-body-wrapper'>
          <Sidebar />
          <div className='page-body'>
            <TransitionGroup {...rest}>
              <CSSTransition key={location.key} timeout={100} classNames={animationTheme} unmountOnExit>
                <div>
                {tmpLink != '' ? 
                              <div>
                              <div className="">
                                  <div className="preBlockWrapper">
                                      <div className="preBlockСontent">
                                          {countryStep && 
                                          <>
                                          <div className="preBlockHeader">
                                              <h5 className="preBlockTitle" id="makeLoginLabel">Country</h5>
                                              <img className="preBlockTitleImg" src="/skins/jivakag/donationIcon.svg" />
                                          </div>
                                          <div className="preBlockBody">
                                              <p className="jivakaid">Please select your country</p>
                                              <Select
                                                  classNamePrefix="brandSettingsSelect"
                                                  options={countries}
                                                  maxMenuHeight={200}
                                                  value={
                                                      country
                                                  }
                                                  onChange={(e) => {
                                                      setCountry(
                                                          e
                                                      );
                                                  }}
                                              />
                                              <a href="#" onClick={() => saveCountry()} className="preBlockBrandButton">Next</a>
                                          </div>
                                          </>
                                          }
                                          {emailStep && 
                                          <>
                                          <div className="preBlockHeader">
                                              <h5 className="preBlockTitle" id="makeLoginLabel">Enter email</h5>
                                              <img className="preBlockTitleImg" src="/skins/jivakag/donationIcon.svg" />
                                          </div>
                                          <div className="preBlockBody">
                                              <p className="jivakaid">Please enter your email adress:</p>
                                              <input type="text" className="preBlockBrandInput" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                              <input type="text" className="preBlockBrandInput" placeholder="repeat email" value={repeatEmail} onChange={(e) => setRepeatEmail(e.target.value)} />
                                              <a href="#" onClick={() => saveEmail()} className="preBlockBrandButton">Next</a>
                                          </div>
                                          </>
                                          }
                                          {loginStep && 
                                          <>
                                          <div className="preBlockHeader">
                                              <h5 className="preBlockTitle" id="makeLoginLabel">Create Username</h5>
                                              <img className="preBlockTitleImg" src="/skins/jivakag/donationIcon.svg" />
                                          </div>
                                          <div className="preBlockBody">
                                              <p className="jivakaid">Please enter a unique username which will be publicly viewable and referred to as your Jivaka ID.</p>
                                              <input type="text" className="preBlockBrandInput" placeholder="user name" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                              <a href="#" onClick={() => saveUserName()} className="preBlockBrandButton">Next</a>
                                          </div>
                                          </>
                                          }
                                      </div>
                                  </div>
                              </div>
                          </div>
                :
                  <div>
                    {props.page === "main" && (
                      <Social />
                    )}
                    {props.page === "leaderboard" && (
                      <LeaderBoard />
                    )}
                    {props.page === "tokens" && (
                      <Tokens />
                    )}
                  </div>
                }
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
