import { configureStore } from "@reduxjs/toolkit";
import token from "./token/reducer";
import user from "./user/reducer"
import userSettings from "./userSettings/reducer";
 
const store = configureStore({
    reducer: {
        token,
		user,
        userSettings
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      }),
});

export default store;