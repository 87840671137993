import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5, Btn } from '../../../AbstractElements';
import { Create, UpcomingScheduleTitle } from '../../../Constant';
import ScheduleListBox from './ScheduleListBox';

const Transactions = () => {
    const ScheduleListData = [
        {
          title: '500 PWR',
          image: '4.jpg',
          icon: 'bag',
          icon2: 'clock',
          date: 'January 3, 2023',
          date2: '09.00',
          color: 'primary',
        },
        {
          title: '200 PWR',
          image: '2.jpg',
          icon: 'bag',
          icon2: 'clock',
          date: 'Febuary 10, 2023',
          date2: '11.00',
          color: 'warning',
        },
      ];
  return (
    <Card className='schedule-card'>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 attrH5={{ className: 'm-0' }}>Transactions</H5>
          <div className='card-header-right-icon'>
            <Btn attrBtn={{ color: 'light-primary', className: 'btn badge-light-primary' }}>+ {Create}</Btn>
          </div>
        </div> 
      </CardHeader>
      <CardBody className='pt-0'>
        <ScheduleListBox data={ScheduleListData} />
      </CardBody>
    </Card>
  );
};

export default Transactions;
