export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'horizontal-wrapper material-type',
				iconType: 'stroke-svg',
			},
			sidebar_setting: 'default-sidebar', 
		},
		color: {
			primary_color: '#02833d', 
			secondary_color: '#f73164', 
			mix_background_layout: 'light-only', 
		},
		router_animation: 'fadeIn'
	};
}
export default ConfigDB;
