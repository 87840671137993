import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ["en", "es", "tr", "id", "ru", "uk", "zh", "pl"],
    nonExplicitSupportedLngs: true,

    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend:  {
      requestOptions: {
        cache: 'no-cache'
      }
    }
  });

  export default i18n;