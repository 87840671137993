import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

const ReferralLink = () => {
    const [referralLink, setReferralLink] = useState("");

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {      
        setReferralLink(params.referralLink); 
        localStorage.setItem('referralLink', params.referralLink);

        navigate("/dashboard");
    }, [referralLink]);

  return (
    <div>{params.referralLink}</div>
  )
}

export default ReferralLink;