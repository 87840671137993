import React from "react";
import { createRoot } from "react-dom/client"
import { Provider } from 'react-redux'
import { createBrowserHistory } from "history";
import App from "./App.jsx";
import store from "./state"

const history = createBrowserHistory();

createRoot(document.getElementById("root")).render(
<React.StrictMode>
    <Provider store={store}>
        <App history={history} />
    </Provider>
</React.StrictMode>
);
