import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        address: '',
        contract: {}
    },
    reducers: {
        setTokenInfo(state, {payload: {address,contract}}) {
            state.address = address;
            state.contract = contract;
        }
    }
})
export const {
    setTokenInfo
  } = tokenSlice.actions
export default tokenSlice.reducer;