import React, {useEffect, useState, Suspense} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {useDispatch} from "react-redux";
import swal from "sweetalert";

import Landing from "./Components/Layouts/Landing";
import Dashboard from "./Layout/Layout";
import ReferralLink from "./Components/Layouts/ReferralLink";
import Loading from "./Components/Loading";
import "./utils/i18n";
import { MagicLogin } from './utils/magiclogin';

import {setTokenInfo} from "./state/token/reducer";
import {setUserEmail} from "./state/userSettings/reducer";

import {apiUrl} from "./constants/config";

import ChartistProvider from './_helper/Chartist/ChartistProvider';
import ChartjsProvider from './_helper/Chartjs/ChartProvider';
import GoogleChartProvider from './_helper/GoogleChart/GoogleChartProvider';
import ProjectProvider from './_helper/Project/ProjectProvider';
import ChatProvider from './_helper/Chat/ChatProvider';
import ContactProvider from './_helper/Contact/ContactProvider';
import TaskProvider from './_helper/Task/TaskProvider';
import GalleryProvider from './_helper/Gallery/GalleryProvider';
import TableProvider from './_helper/Table/TableProvider';
import BookmarkProvider from './_helper/Bookmark/BookmarkProvider';
import TodoProvider from './_helper/Todo/TodoProvider';
import EmailProvider from './_helper/Email/EmailProvider';
import SearchResultProvider from './_helper/SearchResult/SearchResult';
import ProductProvider from './_helper/Ecommerce/Product/ProductProvider';
import CartProvider from './_helper/Ecommerce/Cart/CardProvider';
import FilterProvider from './_helper/Ecommerce/Filter/FilterProvider';
import WishListProvider from './_helper/Ecommerce/Wishlist/WishlistProvider';
import JobSearchProvider from './_helper/JobSearch/JobSearchProvider';
import LearningProvider from './_helper/Learning/LearningProvider';
import FaqProvider from './_helper/Faq/FaqProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';

export default function App(history) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("didToken")) {
            //MLogin();
        }
    }, []);

    const MLogin = async () => { 
        try {
            if (localStorage.getItem("didToken")) {
                let email = await MagicLogin(localStorage.getItem("didToken"));

                if (email) {
                    dispatch(
                        setUserEmail({
                            email: email
                        })
                    );
                } else {
                    //window.location = '/';
                }
            }
        } catch (error) {
            //window.location = '/';
        }
    }

    return (
        <div className="App">
		    <CustomizerProvider>
      <FaqProvider>
        <LearningProvider>
          <JobSearchProvider>
            <WishListProvider>
              <FilterProvider>
                <CartProvider>
                  <ProductProvider>
                    <SearchResultProvider>
                      <EmailProvider>
                        <TodoProvider>
                          <BookmarkProvider>
                            <TableProvider>
                              <GalleryProvider>
                                <TaskProvider>
                                  <ContactProvider>
                                    <ChatProvider>
                                      <ProjectProvider>
                                        <GoogleChartProvider>
                                          <ChartjsProvider>
                                            <ChartistProvider>
                                              <AnimationThemeProvider>
        <Suspense fallback={<Loading />}>
            <BrowserRouter history={history}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Landing 
                                loading={loading} 
                                isAuth={isAuth} 
                                setIsAuth={setIsAuth}
                            />
                        }
                    />
                    <Route path="link" element={<ReferralLink />}>
                        <Route
                            path=":referralLink"
                            element={<ReferralLink />}
                        />
                    </Route>
                    <Route
                        path="/dashboard"
                        element={
                            <Dashboard 
                                loading={loading}
                                page="main" 
                                isAuth={isAuth} 
                                setIsAuth={setIsAuth}
                            />
                        }
                    />
                    <Route
                        path="/dashboard/leaderboard"
                        element={
                            <Dashboard 
                                loading={loading}
                                page="leaderboard" 
                                isAuth={isAuth} 
                                setIsAuth={setIsAuth}
                            />
                        }
                    />
                    <Route
                        path="/dashboard/tokens"
                        element={
                            <Dashboard 
                                loading={loading}
                                page="tokens" 
                                isAuth={isAuth} 
                                setIsAuth={setIsAuth}
                            />
                        }
                    />
                </Routes>
            </BrowserRouter>
        </Suspense>
		                                              </AnimationThemeProvider>
                                            </ChartistProvider>
                                          </ChartjsProvider>
                                        </GoogleChartProvider>
                                      </ProjectProvider>
                                    </ChatProvider>
                                  </ContactProvider>
                                </TaskProvider>
                              </GalleryProvider>
                            </TableProvider>
                          </BookmarkProvider>
                        </TodoProvider>
                      </EmailProvider>
                    </SearchResultProvider>
                  </ProductProvider>
                </CartProvider>
              </FilterProvider>
            </WishListProvider>
          </JobSearchProvider>
        </LearningProvider>
      </FaqProvider>
    </CustomizerProvider>
        </div>
    );
}
