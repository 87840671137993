import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
//import { SmallWidgetsData, SocialWidgetData } from '../../../Data/Social';

import Referrals from './Referrals';
import Transactions from './Transactions';
import FacebookCampaign from './FacebookCampaign';
import FollowerGender from './FollowerGender';
import InstagramSubscription from './InstagramSubscription';
import MobileAppCard from './MobileAppCard';
import SmallWidgets from './SmallWidgets';
import SocialProfileCard from './SocialProfileCard';
import SocialWidget from '../../Common/CommonWidgets/SocialWidget';
import Views from './Views';

const Social = () => {
  const SocialWidgetData = [
    {
      title: 'Personal Volume',
      gros: 22.9,
      total: 12_098,
      subTitle: 'PV',
      status: 'success',
      chart: {
        color: ['var(--theme-deafult)'],
        series: [78],
      },
    },
    {
      title: 'Referral Volume',
      gros: 27.4,
      total: 15_080,
      subTitle: 'RV',
      status: 'success',
      chart: {
        color: ['#FFA941'],
        series: [70],
      },
    },
    {
      title: 'Direct Referrals',
      gros: 14.09,
      total: 12_564,
      subTitle: 'DR',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
    {
      title: 'Team Referrals',
      gros: 14.09,
      total: 12_564,
      subTitle: 'TR',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
    {
      title: 'Leadership',
      gros: 14.09,
      total: 12_564,
      subTitle: 'JVK',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
    {
      title: 'Gifting',
      gros: 14.09,
      total: 12_564,
      subTitle: 'GFT',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
    {
      title: 'Product Tokens',
      gros: 14.09,
      total: 12_564,
      subTitle: 'SKUR',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
    {
      title: 'Rank',
      gros: 14.09,
      total: 1,
      subTitle: '',
      status: 'success',
      chart: {
        color: ['#57B9F6'],
        series: [50],
      },
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' title='Dashboard' />
      <Container fluid={true}>
        <Row>
          <Col xxl='4' xl='5' className='col-ed-5 box-col-5'>
            <Row>
              <Col xl='12' md='6'>
                <SocialProfileCard />
              </Col>
              <Col xl='12' md='6'>
                <MobileAppCard />
              </Col>
            </Row>
          </Col>
          <Col xxl='8' xl='7' className='col-ed-7 box-col-7e'>
            <Row>
              {SocialWidgetData.map((item, i) => (
                <Col md='3' sm='2' key={i}>
                  <SocialWidget data={item} />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xl='12'>
            <Referrals />
          </Col>
          <Col xl='12'>
            <Transactions />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Social;
